import React from 'react';
import { useIsDarkMode } from '../../../utils/darkMode';

const MicrosoftIcon: React.FC = () => {
  const isDarkMode = useIsDarkMode();
  const fillColor = isDarkMode ? '#ffffff' : '#000000';
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.09969 1.68018C1.86785 1.68018 1.67969 1.86792 1.67969 2.10018V10.0802H10.0797V1.68018H2.09969ZM10.9197 1.68018V10.0802H19.3197V2.10018C19.3197 1.86792 19.1315 1.68018 18.8997 1.68018H10.9197ZM1.67969 10.9202V18.9002C1.67969 19.1324 1.86785 19.3202 2.09969 19.3202H10.0797V10.9202H1.67969ZM10.9197 10.9202V19.3202H18.8997C19.1315 19.3202 19.3197 19.1324 19.3197 18.9002V10.9202H10.9197Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default MicrosoftIcon;
