import { createTheme, PaletteMode, Theme } from '@mui/material';

export const createAppTheme = (mode: PaletteMode): Theme =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: mode === 'light' ? '#fff' : '#000000',
        light: mode === 'light' ? '#000000' : '#fff',
        dark: '#2A2A2A',
      },
      secondary: {
        main: mode === 'light' ? '#fff' : '#2A2A2A',
        light: mode === 'light' ? '#2A2A2A' : '#fff',
        dark: '#000000',
      },
      background: {
        default: mode === 'light' ? '#F3F3F3' : '#000000',
        paper: mode === 'light' ? '#fff' : '#1A1A1A',
      },
      info: {
        main: '#00BDC7',
        light: '#00BDC71a',
        dark: mode === 'light' ? '#000' : '#00BDC7',
      },
      success: {
        main: '#5FD855',
        light: 'rgba(95, 216, 85, 0.25)',
      },
      error: {
        main: '#FF5722',
        light: 'rgba(255, 87, 34, 0.25)',
      },
      grey: {
        A100: mode === 'light' ? '#F7F7F7' : '#333333',
        A700:
          mode === 'light' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)',
      },
    },
    typography: {
      fontFamily: `'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
      h1: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '26px',
        color: mode === 'light' ? '#2A2A2A' : '#fff',
      },
      h2: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '22px',
        color: mode === 'light' ? '#2A2A2A' : '#fff',
      },
      h3: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        color: mode === 'light' ? '#000' : '#fff',
      },
      h4: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '22px',
        color: mode === 'light' ? '#273B4A' : '#fff',
      },
      h5: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '16px',
        color: mode === 'light' ? '#000' : '#fff',
      },
      h6: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        color: mode === 'light' ? '#000' : '#fff',
      },
      body1: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        color: mode === 'light' ? '#000' : '#fff',
      },
      body2: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        color: mode === 'light' ? '#000' : '#fff',
      },
      subtitle1: {
        fontSize: '16px',
        lineHeight: '14px',
        fontWeight: 400,
        color: mode === 'light' ? '#000' : '#fff',
      },
    },
  });
