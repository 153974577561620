import React from 'react';

import { Translations } from './common';

const englishTranslations: Translations = {
  noPrediction: 'No prediction or prediction confidence is too low (<10%)',
  loading: 'Loading...',
  noOptions: 'No options',
  noData: (
    <>
      We could not load any data. Try changing a filter.
      <br />
      If something is not working correctly you can send us a{' '}
      <a
        href="mailto:contact-project+coresystemsag1-service-ai-analytics-ui-support@incoming.gitlab.com"
        target=""
      >
        message
      </a>
      .
    </>
  ),
  errorCardText: (
    <>
      Oops.
      <br />
      Looks like this section is not working. Try again later or send us a{' '}
      <a
        href="mailto:contact-project+coresystemsag1-service-ai-analytics-ui-support@incoming.gitlab.com"
        target=""
      >
        message
      </a>
      .
    </>
  ),
  months: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  monthsFull: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  monthsMap: {
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
  },
  durationInDays: (days) => `${days} ${days >= 2 ? 'days' : 'day'}`,
  auth: {
    login: 'Login',
    email: 'Email',
    password: 'Password',
    or: 'or',
    loading: 'Authenticating...',
    marketingMessage: 'Ready for Take Off?',
    invalidCredentials: 'Invalid email or password',
    sessionExpired:
      'Your session has expired. Please log in again to continue using InsightLoop.',
    pilotDescription: (
      <>
        Partnering for Success: Our Collaborative Approach to Empower Your
        Journey. Let us help you to unlock the power of your data –{' '}
        <a href="mailto:sales@coresystems.ch" target="">
          request
        </a>{' '}
        your access now.
      </>
    ),
    unauthorized:
      'The Assistant is currently only available for early-access clients.',
    azure: {
      loginButtonText: 'Login with Microsoft',
      tooltipLabel: 'Need help?',
      tooltipContent:
        'This is the same login you use to access Azure. If not sure, please contact IT support.',
    },
  },
  predictionQuality: {
    openDialog: 'Past prediction quality',
    allPredictions: 'predictions',
    highConfidence: 'High accuracy',
    mediumConfidence: 'Medium accuracy',
    lowConfidence: 'Low accuracy',
    jobDiagnosis: 'Job diagnosis',
    words: 'causes',
    rootCauses: {
      title: 'Root cause prediction quality',
      predicted: 'Predicted root cause(s)',
      used: 'Reported root cause(s)',
    },
    parts: {
      title: 'Parts prediction quality',
      predicted: 'Parts predicted',
      used: 'Parts used',
    },
  },
};

export default englishTranslations;
