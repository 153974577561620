import { useMediaQuery } from '@mui/material';

import { isInsideFSM } from './origin';

export const useIsDarkMode = (): boolean => {
  const isBrowserPreferenceDarkMode = useMediaQuery(
    '(prefers-color-scheme: dark)',
  );
  const isInsideFSMShell = isInsideFSM();

  return !isInsideFSMShell && isBrowserPreferenceDarkMode;
};
