import jwtDecode from 'jwt-decode';
import { AccountInfo } from '@azure/msal-common';
import { useEffect, useState } from 'react';
import { isDefined } from '@insightloop/common-ui';

const BASE_URL = process.env.REACT_APP_API_URL;

const CORESYSTEMS_ACCOUNTS = new Set<string>([
  'fsm-data-coresystems',
  'core-rocysa-de',
  'coresystems_D1',
]);

export const AUTH_SESSION_KEY = 'InsightLoopAuth';

export enum LoginType {
  FSM,
  AZURE,
  USERNAME_PASSWORD,
}

export interface AuthData {
  accountId: string;
  companyId: string;
  identityId: string;
  tenantId: number;
  isInternalAccount: boolean;
  loginType: LoginType;
  token: string;
  expiry: number | null;
  refreshToken: string;
  cluster?: string | null;
  userId?: string;
}

export interface FSMContext {
  account: string;
  accountId: string;
  company: string;
  companyId: string;
  userId: string;
  selectedLocale: string;
  cloudHost: string;
  auth?: {
    access_token: string;
    token_type: string;
    expires_in: number;
  };
}

const getAuthDataFromStorage = (): AuthData | null => {
  const session = sessionStorage.getItem(AUTH_SESSION_KEY);
  if (session === null) {
    return null;
  }
  return JSON.parse(session);
};

export const getTokenFromStorage = (): string | null => {
  const authData = getAuthDataFromStorage();

  return authData === null ? null : authData.token;
};

export const logout = (): void => {
  sessionStorage.clear();
  window.dispatchEvent(new Event('storage'));
};

const AZURE_ACCOUNT_KEY = 'azureAccount';

export const saveAzureAccount = (account: AccountInfo) => {
  sessionStorage.setItem(AZURE_ACCOUNT_KEY, JSON.stringify(account));
};

export const getAzureAccount = (): AccountInfo | null => {
  const account = sessionStorage.getItem(AZURE_ACCOUNT_KEY);

  return account === null ? null : JSON.parse(account);
};

export const useAuthData = (): AuthData | null => {
  const [authData, setAuthData] = useState<AuthData | null>(
    getAuthDataFromStorage,
  );
  useEffect(() => {
    const handleStorage = () => {
      const data = getAuthDataFromStorage();
      setAuthData(data);
    };
    window.addEventListener('storage', handleStorage);

    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  }, []);

  if (authData === null) {
    return null;
  }
  if (authData.expiry === null) {
    return authData;
  }

  const tokenExpired = authData.expiry * 1000 <= Date.now();
  if (tokenExpired) {
    sessionStorage.clear();
    setAuthData(null);
  }
  return authData;
};

export const useAuthenticated = (): boolean => {
  const authData = useAuthData();

  return authData !== null;
};

export const isTokenAboutToExpire = (): boolean => {
  const authData = getAuthDataFromStorage();
  if (authData === null) {
    return true;
  }
  if (authData.expiry === null) {
    return false;
  }
  const fiveMinutesInMs = 5 * 60 * 1000;
  const expiresAtMs = authData.expiry * 1000;

  return expiresAtMs - fiveMinutesInMs <= Date.now();
};

interface FetchTokenParams {
  token: string;
  isInternalAccount: boolean;
  loginType: LoginType;
  accountId?: string;
  companyId?: string;
  userId?: string;
  cluster?: string | null;
}

interface TokenResponse {
  accountId: string;
  companyId: string;
  identityId: string;
  tenantId: number;
  token: string;
  refreshToken: string;

  data?: {
    identityId: string;
    tenantId: number;
    token: string;
    refreshToken: string;
    accountId: string;
    companyId: string;
  };
}

const fetchToken = async ({
  accountId,
  companyId,
  token,
  userId,
  isInternalAccount,
  loginType,
  cluster = null,
}: FetchTokenParams): Promise<void> => {
  return fetch(`${BASE_URL}/auth/api/v1/token`, {
    method: 'POST',
    body: JSON.stringify({
      accountId: accountId || '',
      companyId: companyId || '',
      token,
    }),
  })
    .then((response) => response.json())
    .then((response: TokenResponse) => {
      const {
        token,
        tenantId,
        identityId,
        accountId,
        companyId,
        refreshToken,
      } = isDefined(response.data) ? response.data : response;
      const isDemoFsmTenant = tenantId === -1;
      const { exp } = isDemoFsmTenant
        ? { exp: null }
        : jwtDecode<{ exp: number }>(token);
      const authData: AuthData = {
        accountId,
        companyId,
        identityId,
        tenantId,
        token,
        refreshToken,
        userId,
        cluster,
        loginType,
        isInternalAccount: isInternalAccount || isDemoFsmTenant,
        expiry: exp,
      };
      sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(authData));
      window.dispatchEvent(new Event('storage'));
    });
};

export const fetchRefreshToken = async (): Promise<void> => {
  const authData = getAuthDataFromStorage();
  if (authData === null) {
    throw new Error('No auth data found, cannot refresh token!');
  }
  const {
    accountId,
    companyId,
    isInternalAccount,
    loginType,
    refreshToken,
    token,
  } = authData;

  await fetch(`${BASE_URL}/auth/api/v1/token`, {
    method: 'POST',
    body: JSON.stringify({
      refreshToken,
      token,
      accountId: accountId || '',
      companyId: companyId || '',
    }),
  })
    .then((response) => response.json())
    .then((response: TokenResponse) => {
      const {
        token,
        tenantId,
        identityId,
        accountId,
        companyId,
        refreshToken,
      } = isDefined(response.data) ? response.data : response;
      const isDemoFsmTenant = tenantId === -1;
      const { exp } = isDemoFsmTenant
        ? { exp: null }
        : jwtDecode<{ exp: number }>(token);
      const refreshAuthData: AuthData = {
        accountId,
        companyId,
        identityId,
        tenantId,
        token,
        refreshToken,
        loginType,
        isInternalAccount: isInternalAccount || isDemoFsmTenant,
        expiry: exp,
      };
      sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(refreshAuthData));
      window.dispatchEvent(new Event('storage'));
    });
};

export const loginWithFSM = async (fsmContext: FSMContext) => {
  const { account, accountId, companyId, userId, cloudHost, auth } = fsmContext;
  const cluster = cloudHost.slice(0, 2);
  const isInternalAccount = CORESYSTEMS_ACCOUNTS.has(account);
  if (!isDefined(auth)) {
    throw new Error('FSM Auth data not present!');
  }
  await fetchToken({
    accountId,
    companyId,
    userId,
    cluster,
    isInternalAccount,
    loginType: LoginType.FSM,
    token: auth.access_token,
  });
};

const isInternalUser = (user: string): boolean => {
  return (
    user.match(
      /^.+@(.*coresystems\.ch)|(insightloopprod.onmicrosoft\.com)$/i,
    ) !== null
  );
};

export interface AzureCredentials {
  token: string;
  email: string;
}

export const loginWithAzure = async ({ token, email }: AzureCredentials) => {
  await fetchToken({
    token,
    isInternalAccount: isInternalUser(email),
    loginType: LoginType.AZURE,
  });
};

interface UserCredentials {
  username: string;
  password: string;
}

export const loginWithUsernameAndPassword = async ({
  username,
  password,
}: UserCredentials) => {
  const token = `Basic ${window.btoa(`${username}:${password}`)}`;
  await fetchToken({
    token,
    isInternalAccount: isInternalUser(username),
    loginType: LoginType.USERNAME_PASSWORD,
  });
};
