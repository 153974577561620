import english from './english';
import german from './german';

export enum SupportedLanguage {
  ENGLISH = 'en',
  GERMAN = 'de',
}

export const supportedLanguages: Record<SupportedLanguage, Translations> = {
  [SupportedLanguage.ENGLISH]: english,
  [SupportedLanguage.GERMAN]: german,
};

export const getLanguage = (): SupportedLanguage => {
  const isTestEnv = process.env.NODE_ENV === 'test';
  if (isTestEnv) {
    return SupportedLanguage.ENGLISH;
  }
  const locale = navigator.language;
  const language = locale.slice(0, 2);

  return (
    Object.values(SupportedLanguage).find(
      (supportedLanguage) => supportedLanguage === language,
    ) || SupportedLanguage.ENGLISH
  );
};

export const getTranslations = (): Translations => {
  const language = getLanguage();

  return supportedLanguages[language];
};

type LabelCreator<T> = (params: T) => string;

export interface Translations {
  noPrediction: string;
  loading: string;
  noOptions: string;
  durationInDays: LabelCreator<number>;
  noData: JSX.Element;
  errorCardText: JSX.Element;
  months: string[];
  monthsFull: string[];
  monthsMap: { [key: string]: string };
  auth: {
    login: string;
    email: string;
    password: string;
    or: string;
    loading: string;
    marketingMessage: string;
    pilotDescription: JSX.Element;
    unauthorized: string;
    invalidCredentials: string;
    sessionExpired: string;
    azure: {
      loginButtonText: string;
      tooltipLabel: string;
      tooltipContent: string;
    };
  };
  predictionQuality: {
    openDialog: string;
    allPredictions: string;
    highConfidence: string;
    mediumConfidence: string;
    lowConfidence: string;
    jobDiagnosis: string;
    words: string;
    rootCauses: {
      title: string;
      predicted: string;
      used: string;
    };
    parts: {
      title: string;
      predicted: string;
      used: string;
    };
  };
}
