import { ShellSdk } from 'fsm-shell';

const SALES_FORCE_ORIGIN = 'coresystems-dev-ed.develop.lightning.force.com';

export const isInsideFSM = (): boolean => {
  // Because that's what the FSM lib actually returns
  const isInsideAnIFrame = ShellSdk.isInsideShell();
  const parentOrigin = document.referrer;

  return isInsideAnIFrame && !parentOrigin.match(SALES_FORCE_ORIGIN);
};

export const isInsideSalesForce = (): boolean => {
  // Because that's what the FSM lib actually returns
  const isInsideAnIFrame = ShellSdk.isInsideShell();
  const parentOrigin = document.referrer;

  return isInsideAnIFrame && parentOrigin.match(SALES_FORCE_ORIGIN) !== null;
};
