import React from 'react';

import { Translations } from './common';

const germanTranslations: Translations = {
  noPrediction:
    'Keine Vorhersage oder Vorhersagezuverlässigkeit ist zu gering (<10%)',
  loading: 'Laden...',
  noOptions: 'Keine Optionen',
  noData: (
    <>
      Wir konnten keine Daten laden. Versuchen Sie den Filter zu ändern.
      <br />
      Wenn etwas nicht richtig funktioniert, können Sie uns eine{' '}
      <a
        href="mailto:contact-project+coresystemsag1-service-ai-analytics-ui-support@incoming.gitlab.com"
        target=""
      >
        Nachricht
      </a>{' '}
      senden.
    </>
  ),
  errorCardText: (
    <>
      Ups.
      <br />
      Es sieht so aus, als ob dieser Bereich nicht funktioniert. Versuchen Sie
      es später noch einmal oder senden Sie uns eine{' '}
      <a
        href="mailto:contact-project+coresystemsag1-service-ai-analytics-ui-support@incoming.gitlab.com"
        target=""
      >
        Nachricht
      </a>
      .
    </>
  ),
  months: [
    'Jan',
    'Feb',
    'März',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Okt',
    'Nov',
    'Dez',
  ],
  monthsFull: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  monthsMap: {
    Jan: 'Januar',
    Feb: 'Februar',
    März: 'März',
    Apr: 'April',
    Mai: 'Mai',
    Jun: 'Juni',
    Jul: 'Juli',
    Aug: 'August',
    Sept: 'September',
    Okt: 'Oktober',
    Nov: 'November',
    Dez: 'Dezember',
  },
  durationInDays: (days) => `${days} ${days >= 2 ? 'Tage' : 'Tag'}`,
  auth: {
    login: 'Login',
    email: 'Email',
    password: 'Passwort',
    or: 'oder',
    loading: 'Authentifizierung...',
    marketingMessage: 'Bereit zum Abheben?',
    invalidCredentials: 'Ungültige E-Mail oder Passwort',
    sessionExpired:
      'Ihre Session ist abgelaufen. Bitte melden Sie sich erneut an, um InsightLoop weiter zu nutzen.',
    pilotDescription: (
      <>
        Partnerschaft für den Erfolg: Unser kollaborativer Ansatz, um Ihre Reise
        zu unterstützen. Lassen Sie uns Ihnen helfen, das Potenzial Ihrer Daten
        zu nutzen - fordern Sie jetzt Ihren{' '}
        <a href="mailto:sales@coresystems.ch" target="">
          Zugang
        </a>{' '}
        an.
      </>
    ),
    unauthorized: 'Der Assistent ist derzeit nur für Pilot-Kunden verfügbar.',
    azure: {
      loginButtonText: 'Login mit Microsoft',
      tooltipLabel: 'Benötigen Sie Hilfe?',
      tooltipContent:
        'Dies ist das gleiche Login, welches Sie für den Zugang zu Azure verwenden. Wenn Sie nicht sicher sind, wenden Sie sich bitte an den IT-Support.',
    },
  },
  predictionQuality: {
    openDialog: 'Qualität früherer Vorhersagen',
    allPredictions: 'Vorhersagen',
    highConfidence: 'Hohe Genauigkeit',
    mediumConfidence: 'Mittlere Genauigkeit',
    lowConfidence: 'Geringe Genauigkeit',
    jobDiagnosis: 'Job Diagnose',
    words: 'Wörter',
    rootCauses: {
      title: 'Qualität der Ursachenvorhersage',
      predicted: 'Vorausgesagte Ursache(n)',
      used: 'Wirkliche Ursache(n)',
    },
    parts: {
      title: 'Qualität der Ersatzteilvorhersage',
      predicted: 'Vorausgesagte Ersatzteile',
      used: 'Verwendete Ersatzteile',
    },
  },
};

export default germanTranslations;
