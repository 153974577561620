import { useCallback } from 'react';
import { Amplify, AWSCloudWatchProvider, Logger } from 'aws-amplify';
import { format } from 'date-fns';
import { EnvironmentName, isDefined } from '@insightloop/common-ui';

import { useAuthData } from './auth';

const today = format(new Date(), 'yyyy/MM/dd');

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
  },
  Logging: {
    logGroupName: 'assistant-log-group',
    logStreamName: `assistant-log-stream-${today}`,
    region: process.env.REACT_APP_AWS_REGION,
  },
});

const logger = new Logger('assistant-logger', 'DEBUG');
Amplify.register(logger);
logger.addPluggable(new AWSCloudWatchProvider());

export const useLogger = () => {
  const authData = useAuthData();
  const tenantId = authData?.tenantId;
  const environment = (process.env.REACT_APP_ENVIRONMENT_NAME ||
    'development') as EnvironmentName;

  const logError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...msg: any[]): void => {
      if (environment === 'development') {
        console.error(...msg);
      }
      if (!isDefined(tenantId)) {
        logger.error(...msg);
      } else {
        logger.error(`[tenant:${tenantId}]`, ...msg);
      }
    },
    [tenantId],
  );

  const logInfo = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...msg: any[]): void => {
      if (environment === 'development') {
        console.info(...msg);
      }
      if (!isDefined(tenantId)) {
        logger.info(...msg);
      } else {
        logger.info(`[tenant:${tenantId}]`, ...msg);
      }
    },
    [tenantId],
  );

  return { logError, logInfo };
};
